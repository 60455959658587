import React from 'react'; 
export const ServicesBlock = () => (
        <>
          <section id="about" className="feature">
            <h2>Our Services</h2>
            <div className={'row'}>
              <div className={'col-6'}>
                <p>We offer a wide range of services to help you grow your business online. Whether you need a new website, a redesign or a custom API integration, we got your back. We also offer SEO and conversion rate optimization services to help you get the most out of your online presence.</p>
              </div>
              <div className={'col-6'}>
                <ul className={'alt'}>
                  <li>Website development</li>
                  <li>Conversion rate optimizations</li>
                  <li>E-commerce solutions</li>
                  <li>AI integrations and optimizations</li>
                  <li>A/B testing</li>
                  <li>(Re)Design</li>
                  <li>SEO</li>
                  <li>Performance analysis and improvements</li>
                  <li>Custom API integrations</li>
                  <li>Hosting</li>
                  <li>Support</li>
                </ul>
              </div>
            </div>
          </section>
        </>
    );