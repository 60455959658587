import React from 'react';
import PropTypes from 'prop-types';

const Usps = ({ usps }) => {
  return (
    <section className={'border-0'}>
      <div className="row">
        {usps.map((usp, index) => (
          <div key={index} className="col-4 col-12-medium align-center">
            <h3>{usp.title}</h3>
            <p className="usp-description align-justify">{usp.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

Usps.propTypes = {
  usps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Usps;
